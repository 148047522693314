.logo {
  display: inline-block;
  img {
    @include breakpoint(medium down) {
      max-width: 180px;
    }
  }
}

.sticky-container {
  z-index: 10;
  height:67px;
  @include breakpoint (medium) {
    height:75px;
  }
  @include breakpoint (large) {
    height:109px;
  }
  .is-stuck {
    .content-header {
      box-shadow:none;
      .parallax {
        display:none;
      }
      .filter-header {
        text-align:center;
        //padding:2rem 0 1rem;
        margin-bottom:-1px;
        h1 {
          margin-bottom:0;
        }
      }
    }
    > div:nth-child(2) {
      padding-top:2rem;
      padding-bottom:.5rem;
    }
    > div:last-child {
      padding-bottom:1rem;
      box-shadow: 0 10px 10px 0 $gray6;
    }
  }
}

.header {
  &::after {
    position: absolute;
    content: ' ';
    right: 0;
    bottom: -1rem;
    left: 0;
    height: 1rem;
    z-index: 1;
    background: url('/images/home/tear/black-top.png') bottom center repeat-x;
  }
  &.cta-added::after {
    bottom:auto;
  }
  background-color: rgba(0,
  0,
  0,
  1);
  .row {
    max-width:90rem;
  }
  .top-bar {
    padding-top: 0.5rem;
    a {
      text-transform: uppercase;
    }
    
    @include breakpoint (medium) {
      &-left,&-right {
        width:100%;
      }
      &-right {
        float:none;
        margin:0 auto;
      }
    }
    @media screen and (min-width:1200px) {
      &-left,&-right {
        width:80%;
      }
    }
  }
  &--sm {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .nav-bar {
    @include breakpoint (large) {
      border-top: 1px solid $medium-gray;
      margin-top:.5rem;
    }
  }
}
.header-right {
  display:flex;
  align-items:center;
  flex-direction:row-reverse;
}
.hamburger {
  float: right;
  padding: 1rem;
  @include breakpoint(large up) {
    display: none;
  }
}

.top-bar-grid {
  //display: grid;
  //grid-template-rows: 50% 50%;
  @media screen and (min-width:1200px) {
    padding-top:.5rem;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    //border-bottom: 1px solid $medium-gray;
    &.padded.short {
      padding-top:0;
      padding-bottom:0;
      @media screen and (min-width:1104px) {
        padding-top:.5rem;
        padding-bottom:.5rem;
      }
    }
  }
}

.nav-phone {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  @include breakpoint(medium down) {
    display: none;
  }
  &__txt {
    line-height: 1;
    margin-right: 0.5rem;
    color: $secondary-color;
    font-size: 0.9rem;
    text-transform: uppercase;
    text-align: right;
  }
  &__slash {
    color: $medium-gray;
    letter-spacing: -1px;
  }
  &__number {
    color: $white;
    font-size: 2rem;
    line-height: 1;
  }
}

.nav-phone-sm {
  padding-left: 0.3rem;
  font-weight: 700;
  @include breakpoint(medium only) {
    font-size: 1.2rem;
    font-weight: 500;
  }
  @include breakpoint(large up) {
    display: none;
  }
  &__txt {
    color: $primary-color;
    line-height: 1;
    text-transform: uppercase;
    @include breakpoint(small only) {
      font-size: 0.9rem;
    }
    @include breakpoint(medium only) {
      display: inline-block;
      margin: 0 0.5rem;
    }
  }
  &__number {
    color: $white;
    white-space: nowrap;
    font-weight: 700;
    @include breakpoint(small only) {
      font-size: 0.9rem;
    }
  }
}

.nav-top-links {
  padding-top:.5rem;
  @include breakpoint(medium down) {
    display: none;
  }
  a {
    color: $secondary-color;
    font-size: 0.9rem;
    font-weight: 700;
    padding: 0.5rem;
  }
  &__icon {
    font-size: 1.1rem;
    color: $secondary-color;
  }
}

.menu {
  @include breakpoint(large up) {
    display: flex;
    justify-content: space-between;
  }
  li {
    a {
      color: $white;
      font-size: 1.3rem;
      font-weight: 700;
      padding: 0.5rem 1rem;
      @include breakpoint(large up) {
        font-size: 1rem;
        padding: 1rem;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .header {
    padding: 0;
  }
  .menu>li {
    display: inherit;
  }
  .dropdown.menu .submenu {
    position: relative;
    border: 0;
  }
}